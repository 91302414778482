import React from "react"
import { Button, message, Table } from "antd"
import { navigate } from "gatsby"
import { getGithubReportItem } from "../../api/item"
import { getUniqueOrder } from "../../api/order"
import { getReportRank } from "../../api/report"
import Layout from "../../layouts"
import { getCookie } from "../../utils/helper"
import { TOKEN } from "../../utils/constant"

import "./index.css"

// 报告数据统计页
class Report extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      itemId: "",
      purchased: false,
      canBuyReport: false,
      languageNums: [],
      repoNums: [],
      issueNums: [],
      commitNums: [],
      addLines: [],
      deleteLines: [],
    }
  }

  componentWillMount() {
    this.init()
    this.initReportRank()
  }

  init = async () => {
    let res = await getGithubReportItem()
    if (res === undefined || !res.data.code === "0000") {
      return
    }
    let data = res.data.data
    if (!data) {
      return
    }
    this.setState({ itemId: data.itemId })
    res = await getUniqueOrder(data.itemId)
    if (res.data.code === "0000") {
      this.setState({ purchased: true })
    }
  }

  initReportRank = async () => {
    let res = await getReportRank()
    if (res === undefined || !res.data.code === "0000") {
      return
    }
    let data = res.data.data
    if (!data) {
      return
    }
    const {
      languageNums,
      repoNums,
      issueNums,
      commitNums,
      addLines,
      deleteLines,
      canBuyReport,
    } = data
    this.setState({
      languageNums: JSON.parse(languageNums),
      repoNums: JSON.parse(repoNums),
      issueNums: JSON.parse(issueNums),
      commitNums: JSON.parse(commitNums),
      addLines: JSON.parse(addLines),
      deleteLines: JSON.parse(deleteLines),
      canBuyReport,
    })
  }

  handleBuy = () => {
    if (!this.state.canBuyReport) {
      message.warn("购买功能暂未开放")
      return
    }
    const token = getCookie(TOKEN)
    if (token === null) {
      message.warn("请登录之后进行操作")
      return
    }
    navigate(`/items?id=${this.state.itemId}&type=report`)
  }

  handleView = () => {
    navigate(`/reports/config`)
  }

  render() {
    const {
      languageNums,
      repoNums,
      issueNums,
      commitNums,
      addLines,
      deleteLines,
    } = this.state
    const columns = [
      {
        title: "排名",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "昵称",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "数量",
        dataIndex: "num",
        key: "num",
      },
    ]

    let btn = null
    if (this.state.purchased) {
      btn = <Button onClick={this.handleView}>查看我的 GitHub 报告配置</Button>
    }
    if (!this.state.purchased) {
      btn = (
        <Button type="primary" onClick={this.handleBuy}>
          我是公众号主，我要购买引流
        </Button>
      )
    }
    return (
      <Layout isSpacerDisplay={true} title="GitHub 报告">
        <div className="mdnice-report-container">
          <div className="mdnice-report-inner-container">
            {btn}
            {this.state.canBuyReport && (
              <a
                target="_blank"
                href="https://mp.weixin.qq.com/s/3HZVxoiYPY80WcdiPhDApw"
                className="mdnice-report-sentence-explain"
              >
                2020 年度 GitHub 报告词条解释
              </a>
            )}
            <h2>2020 年度 GitHub 报告排行榜</h2>
            <span>（仅统计公开仓库）</span>
            <div className="mdnice-report-statistics">
              <div className="mdnice-report-one-table">
                <h3>使用编程语言最多的人</h3>
                <Table bordered dataSource={languageNums} columns={columns} />
              </div>
              <div className="mdnice-report-one-table">
                <h3>提交仓库最多的人</h3>
                <Table bordered dataSource={repoNums} columns={columns} />
              </div>
              <div className="mdnice-report-one-table">
                <h3>社区提问最多的人</h3>
                <Table bordered dataSource={issueNums} columns={columns} />
              </div>
              <div className="mdnice-report-one-table">
                <h3>提交数量最多的人</h3>
                <Table bordered dataSource={commitNums} columns={columns} />
              </div>
              <div className="mdnice-report-one-table">
                <h3>增加代码行数最多的人</h3>
                <Table bordered dataSource={addLines} columns={columns} />
              </div>
              <div className="mdnice-report-one-table">
                <h3>删除代码行数最多的人</h3>
                <Table bordered dataSource={deleteLines} columns={columns} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Report
